'use client';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, LinkButton } from '@ui/button';
import { Heading } from '@web/atoms';
import {
  LoadingView,
  ProductSelector,
  IngredientModal,
  useIngredientModal,
  Footer,
} from '@web/components';
import { useReporter } from '../../reporter';
import { useSessionCustomer } from '../../customer';
import { RedirectToLogin } from '../../router';
import { FOOTER_CONTENT } from '../../testing/constants';
import { AccountHeader } from '../AccountHeader';
import { SubtitlePrice } from '../FreshRecipesSelectionForm/styles';
import { StyledEditRecipesFormContainer } from '../EditRecipesFlowContainer/styles';
import {
  userUpdatesBakedRecipesEvent,
  userExpandsBakedRecipeEvent,
  userViewsBakedRecipesPage,
} from './events.js';
import {
  SubtitleHeading,
  ButtonContainer,
  Subtext,
  GFWarningText,
} from './styles.js';

export const DryRecipesSelectionForm = ({
  cancelButtonText,
  cancelTarget,
  cancelAction,
  defaultRecipes,
  headline,
  subheadline,
  modals,
  onSubmit,
  recipes,
  unavailableRecipes,
  submitButtonText,
  links,
  logoUrl,
  logoSrText,
  hasGFPlan,
  GFWarning,
  getConfigFromRecipes,
}) => {
  const reporter = useReporter();
  const [selectedRecipes, setSelectedRecipes] = useState(defaultRecipes);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { modal, onRecipeDetailsClick } = useIngredientModal(modals);

  const { price, message } = getConfigFromRecipes(selectedRecipes);

  const availableRecipes = recipes.filter(
    ({ value }) => !unavailableRecipes.includes(value),
  );

  const onDetailsClickWithReporter = recipe => {
    reporter.tag(userExpandsBakedRecipeEvent(recipe));
    onRecipeDetailsClick(recipe.value);
  };

  const {
    customer,
    isActive,
    isLoading: isCustomerLoading,
  } = useSessionCustomer();

  const onChange = recipe => {
    setSelectedRecipes(recipe);
  };

  const onSubmitWithReporter = async event => {
    event.preventDefault();
    const availableRecipesValues = availableRecipes.map(({ value }) => value);
    reporter.tag(
      userUpdatesBakedRecipesEvent(
        availableRecipesValues,
        defaultRecipes,
        selectedRecipes,
      ),
    );
    setIsSubmitting(true);
    await onSubmit(selectedRecipes);
    setIsSubmitting(false);
  };

  const isSaveDisabled = selectedRecipes?.length === 0 || isSubmitting;

  useEffect(() => {
    if (!isCustomerLoading)
      reporter.tag(userViewsBakedRecipesPage(availableRecipes.length));
  }, [reporter, isCustomerLoading, availableRecipes.length]);

  if (!isCustomerLoading && !isActive) {
    return <RedirectToLogin />;
  }

  if (isCustomerLoading) {
    return <LoadingView />;
  }

  return (
    <>
      <AccountHeader
        links={links}
        logoUrl={logoUrl}
        logoSrText={logoSrText}
        name={customer.first_name}
      />
      <StyledEditRecipesFormContainer>
        <Heading
          headingLevel="h1"
          typography={{ desktop: 'heading1', mobile: 'heading2' }}
          headingText={headline}
          position="center"
        />
        <form onSubmit={onSubmitWithReporter}>
          <SubtitleHeading>{subheadline}</SubtitleHeading>
          {hasGFPlan && <GFWarningText>{GFWarning}</GFWarningText>}
          <ProductSelector
            products={recipes}
            selected={selectedRecipes ?? []}
            onChange={onChange}
            onDetailsClick={onDetailsClickWithReporter}
            unavailableProducts={unavailableRecipes}
            buttonIcon="plus"
          />
          <ButtonContainer>
            <div>
              <SubtitlePrice>{price}</SubtitlePrice>
              {message && <Subtext>{message}</Subtext>}
            </div>
            <Button disabled={isSaveDisabled} type="submit">
              {submitButtonText}
            </Button>
            {cancelAction ? (
              <Button onClick={cancelAction} variant="secondary" fullWidth>
                {cancelButtonText}
              </Button>
            ) : (
              <LinkButton href={cancelTarget} variant="secondary" fullWidth>
                {cancelButtonText}
              </LinkButton>
            )}
          </ButtonContainer>
        </form>
      </StyledEditRecipesFormContainer>
      <IngredientModal {...modal} />
      <Footer {...FOOTER_CONTENT} />
    </>
  );
};

DryRecipesSelectionForm.propTypes = {
  cancelButtonText: PropTypes.string.isRequired,
  cancelTarget: PropTypes.string.isRequired,
  cancelAction: PropTypes.func,
  defaultRecipes: PropTypes.array.isRequired,
  headline: PropTypes.string.isRequired,
  modals: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  recipes: PropTypes.arrayOf(PropTypes.object.isRequired),
  submitButtonText: PropTypes.string.isRequired,
  subheadline: PropTypes.string.isRequired,
  unavailableRecipes: PropTypes.array,
  links: PropTypes.array.isRequired,
  logoUrl: PropTypes.string.isRequired,
  logoSrText: PropTypes.string.isRequired,
  hasGFPlan: PropTypes.bool,
  GFWarning: PropTypes.string.isRequired,
  getConfigFromRecipes: PropTypes.func.isRequired,
};
