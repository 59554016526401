export const userUpdatesExtrasEvent = (
  available_addons,
  previous_addons,
  new_addons,
) => ({
  type: 'User Updates Extras',
  data: {
    available_addons,
    previous_addons,
    new_addons,
  },
});

export const userExpandsExtraDetailsEvent = ({ value }) => ({
  type: 'User Expands Extras Details',
  data: {
    recipe: value,
  },
});

export const userViewsExtraScreen = ({
  availableExtras,
  unavailableExtras,
}) => ({
  type: 'View Extras Recipes Screen',
  data: {
    available_extras: availableExtras,
    unavailable_extras: unavailableExtras,
  },
});

export const userClickAddAddons = () => ({
  type: 'Clicks add extras',
});
