import React from 'react';
import PropTypes from 'prop-types';
import { Button, LinkButton } from '@ui/button';
import { StickyNav } from '@web/molecules';
import { ButtonContainer, StyledAddonsBar, Subtext } from './styles';

export const AddonsBar = ({
  shown,
  numAddonsSelected,
  submitButtonText,
  cancelButtonText,
  cancelAction,
  cancelTarget,
  isSaveDisabled = false,
  inEditFlow,
}) => {
  return (
    <StickyNav shown={shown}>
      <StyledAddonsBar>
        <ButtonContainer>
          <Subtext style={{ width: `100%` }}>
            {numAddonsSelected} {numAddonsSelected === 1 ? 'Extra' : 'Extras'}{' '}
            in your subscription
          </Subtext>
          {cancelAction ? (
            <Button
              onClick={cancelAction}
              horizontalPadding={40}
              percentWidthMobile={33}
              variant="secondary"
            >
              {cancelButtonText}
            </Button>
          ) : (
            <LinkButton
              href={cancelTarget}
              horizontalPadding={40}
              percentWidthMobile={33}
              variant="secondary"
            >
              {cancelButtonText}
            </LinkButton>
          )}
          {inEditFlow ? (
            <Button
              disabled={isSaveDisabled}
              horizontalPadding={40}
              percentWidthMobile={60}
              type="submit"
            >
              {submitButtonText}
            </Button>
          ) : (
            <Button
              disabled={isSaveDisabled}
              horizontalPadding={40}
              percentWidthMobile={60}
              variant={'spinach'}
              type="submit"
            >
              {submitButtonText}
            </Button>
          )}
        </ButtonContainer>
      </StyledAddonsBar>
    </StickyNav>
  );
};

AddonsBar.displayName = 'AddonsBar';

AddonsBar.propTypes = {
  shown: PropTypes.bool.isRequired,
  cancelAction: PropTypes.func,
  cancelTarget: PropTypes.string,
  numAddonsSelected: PropTypes.number.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  isSaveDisabled: PropTypes.bool.isRequired,
  inEditFlow: PropTypes.bool,
};
