'use client';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(4)};
  width: 100%;
  max-width: 512px;
  margin: ${props =>
    props.theme.spacing(props.isBakedCustomer ? 7 : 10, 'auto', 10)};
  padding: ${props => props.theme.spacing(0, 8, 0, 8)};

  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(2)};
  }
`;

export const Subtext = styled.p`
  text-align: center;
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.text.tertiary};
  padding: ${props => props.theme.spacing(4)};
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(2)};
  }
`;

export const SubtitleHeading = styled.p`
  text-align: center;
  max-width: 688px;
  margin: ${props => props.theme.spacing(2)} auto 0;
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.text.primary};
  padding-top: ${props => props.theme.spacing(6)};
  padding-bottom: ${props => props.theme.spacing(10)};
  @media ${props => props.theme.tablet} {
    padding-top: ${props => props.theme.spacing(2)};
    padding-bottom: ${props => props.theme.spacing(10)};
    ${props => props.theme.typography.body};
  }
`;

export const GFWarningText = styled.p`
  color: ${({ theme }) => theme.colors.status.errorPrimary};
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.14px;
  margin: ${props => props.theme.spacing(0, 8, 10, 8)};
`;
