'use client';
import styled from 'styled-components';

export const StyledAddPupSuccess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Title = styled.h1`
  ${props => props.theme.typography.heading1}
  margin-top: ${({ theme }) => theme.spacing(12)};
`;

export const Subtitle = styled.span`
  ${props => props.theme.typography.subheading1}
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const InfoText = styled.span`
  ${props => props.theme.typography.body}
`;

export const InfoContainer = styled.div`
  display: grid;
  margin: ${({ theme }) => theme.spacing(4, 0, 8)};
`;
