'use client';
import styled from 'styled-components';

export const SubtitleHeading = styled.p`
  text-align: center;
  max-width: 688px;
  margin: ${props => props.theme.spacing(2)} auto 0;
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.text.primary};
  padding-top: ${props => props.theme.spacing(3)};
  padding-bottom: ${props => props.theme.spacing(5)};
  @media ${props => props.theme.tablet} {
    padding-top: ${props => props.theme.spacing(2)};
    padding-bottom: ${props => props.theme.spacing(12)};
    ${props => props.theme.typography.body};
  }
`;

export const StyledHeadingWrapper = styled.div`
  padding: ${props => props.theme.spacing(6, 0, 4, 0)};
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(15, 0, 2, 0)};
  }
`;

export const CategoryHeading = styled.p`
  text-align: center;
  max-width: 688px;
  margin: ${props => props.theme.spacing(2)} auto 0;
  color: ${props => props.theme.colors.text.primary};
  padding-top: ${props => props.theme.spacing(6)};
  padding-bottom: ${props => props.theme.spacing(3)};
  ${props => props.theme.typography.heading2};
  @media ${props => props.theme.tablet} {
    padding-top: ${props => props.theme.spacing(2)};
    ${props => props.theme.typography.heading4};
  }
`;

export const Subtext = styled.p`
  text-align: center;
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.text.primary};
`;

export const ButtonContainer = styled.div`
  margin: auto;
  max-width: 512px;
  display: grid;
  text-align: center;
  width: 100%;
  gap: ${props => props.theme.spacing(4)};
  padding: ${props => props.theme.spacing(16, 4, 40)};
  @media ${props => props.theme.mobileS} {
    gap: ${props => props.theme.spacing(6)};
    padding: ${props => props.theme.spacing(2, 4, 24)};
  }
`;

export const AddonCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: min-content;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 0 1rem;
  margin: auto;
  max-width: fit-content;
  &:last-child {
    /* border is needed to replace margin due to how box-model collapsing margins */
    border-right: ${props => props.theme.spacing(5)} solid transparent;
    padding-bottom: ${props => props.theme.spacing(4)};
  }
  @media ${props => props.theme.tablet} {
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(450px, max-content)); */
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    padding-top: ${props => props.theme.spacing(2)};
    padding-bottom: ${props => props.theme.spacing(4)};
    ${props => props.theme.typography.heading4};
  }

  @media ${props => props.theme.laptop} {
    grid-template-columns: repeat(3, 1fr);
  }
`;
