'use client';
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, LinkButton } from '@ui/button';
import { Heading } from '@web/atoms';
import {
  LoadingView,
  Footer,
  AddonSections,
  AddOnQuickView,
} from '@web/components';
import { AccountHeader } from '../AccountHeader';
import { AddonsBar } from '../AddonsBar';
import { useRefInView, useAddonModal } from '../../hooks';
import { FOOTER_CONTENT } from '../../testing/constants';
import { useSessionCustomer } from '../../customer';
import { useReporter } from '../../reporter';
import { RedirectToLogin } from '../../router';
import { ADDON_PRODUCT_TYPES } from '../constants';
import {
  userExpandsExtraDetailsEvent,
  userUpdatesExtrasEvent,
  userViewsExtraScreen,
  userClickAddAddons,
} from './events';
import { NOT_SELECTABLE_TEXT } from './constants';
import {
  ButtonContainer,
  Subtext,
  SubtitleHeading,
  StyledHeadingWrapper,
} from './styles';

export const AddOnSelectionForm = ({
  headline,
  subheadline,
  onSubmit,
  defaultAddons,
  unavailableAddons,
  links,
  logoUrl,
  logoSrText,
  modals,
  submitButtonText,
  cancelButtonText,
  cancelTarget,
  cancelAction,
  inEditFlow,
  addonSections,
  isReactivating,
}) => {
  const reporter = useReporter();
  const defaultAddonsNotAllergic = defaultAddons.filter(
    ({ sku: defaultSku }) => !unavailableAddons.includes(defaultSku),
  );
  const callViewExtrasEventRef = useRef(true);
  const [selectedAddons, setSelectedAddons] = useState(
    defaultAddonsNotAllergic.reduce(
      (cur, addon) => ({
        ...cur,
        [addon.sku]: {
          sku: addon.sku,
          quantity: addon.quantity,
          frequency: addon.frequency,
          recurrence: addon.recurrence,
          checked: true,
          productType: addon.product_type,
        },
      }),
      {},
    ),
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    customer,
    isActive,
    isLoading: isCustomerLoading,
  } = useSessionCustomer();

  const [refInView, ref] = useRefInView(true);

  const addons = addonSections.flatMap(section => section.addons);

  const { modal, onAddonDetailsClick } = useAddonModal(modals);

  const availableAddons = addons.filter(
    ({ sku }) => !unavailableAddons.includes(sku),
  );

  const handleChange = ({ sku, ...values }) => {
    if (values.checked) reporter.tag(userClickAddAddons());
    setSelectedAddons(selectedAddons => ({
      ...selectedAddons,
      [sku]: {
        ...selectedAddons[sku],
        ...values,
        sku,
      },
    }));
  };

  const onDetailsClickWithReporter = ({ value }) => {
    reporter.tag(userExpandsExtraDetailsEvent({ value }));
    onAddonDetailsClick(value);
  };

  const checkedAddons = Object.keys(selectedAddons)
    .filter(sku =>
      Object.values(ADDON_PRODUCT_TYPES).includes(
        selectedAddons[sku].productType,
      ),
    )
    .filter(sku => selectedAddons[sku].checked)
    .map(sku => ({ sku, ...selectedAddons[sku] }));

  const selectedSpecialtyAddons = Object.keys(selectedAddons)
    .filter(
      sku =>
        !Object.values(ADDON_PRODUCT_TYPES).includes(
          selectedAddons[sku].productType,
        ),
    )
    .map(sku => ({ sku, ...selectedAddons[sku] }));

  const onSubmitWithReporter = async event => {
    //prevents specialty items from being removed when navigating away from the addon selection page
    const allSelectedAddons = selectedSpecialtyAddons.length
      ? [...checkedAddons, ...selectedSpecialtyAddons]
      : checkedAddons;

    event.preventDefault();
    if (addonSelectionChanges(defaultAddons, allSelectedAddons)) {
      reporter.tag(
        userUpdatesExtrasEvent(
          availableAddons.map(({ title, sku }) => ({
            sku,
            title,
          })),
          defaultAddons.map(({ sku, frequency, quantity }) => ({
            sku,
            frequency,
            quantity,
          })),
          checkedAddons.map(({ sku, frequency, quantity }) => ({
            sku,
            frequency,
            quantity,
          })),
        ),
      );
    }
    setIsSubmitting(true);
    await onSubmit(allSelectedAddons);
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (!isCustomerLoading && callViewExtrasEventRef.current) {
      reporter.tag(
        userViewsExtraScreen({
          availableExtras: availableAddons?.map(({ sku }) => sku),
          unavailableExtras: unavailableAddons,
        }),
      );
      callViewExtrasEventRef.current = false;
    }
  }, [
    reporter,
    isCustomerLoading,
    availableAddons,
    unavailableAddons,
    callViewExtrasEventRef,
  ]);

  const addonSelectionChanges = (existingAddons, updateAddons) => {
    return !(
      existingAddons.length === updateAddons.length &&
      existingAddons.every(existingAddon =>
        updateAddons.some(
          updatedAddon =>
            updatedAddon.sku === existingAddon.sku &&
            updatedAddon.frequency === existingAddon.frequency &&
            updatedAddon.quantity === existingAddon.quantity,
          updateAddons.recurrence === existingAddon.recurrence,
        ),
      )
    );
  };

  const numAddonsSelected = checkedAddons.length;
  const hasAddonsSelected = numAddonsSelected > 0;
  const isSaveDisabled =
    (!hasAddonsSelected && !defaultAddons.length && !inEditFlow) ||
    isSubmitting; // if the user doesn't have any addons selected and doesn't have any existing any addons and is directly hitting the addons edit page then we disable the Save button i.e. they can only cancel.  Otherwise, if the user doesn't have any selected addons but they do have subscription addons, then we want them to be able to delete the addon selection so we need to keep the Save button enabled.  Finally, if a user is in the edit flow we want them to be able to Skip this step so the button is always enabled.

  if (!isCustomerLoading && !isActive) {
    return <RedirectToLogin />;
  }
  if (isCustomerLoading) {
    return <LoadingView />;
  }

  return (
    <>
      <AccountHeader
        links={links}
        logoUrl={logoUrl}
        logoSrText={logoSrText}
        name={customer.first_name}
      />
      <form onSubmit={onSubmitWithReporter}>
        <StyledHeadingWrapper>
          <Heading
            headingLevel="h1"
            typography={{ desktop: 'heading1', mobile: 'heading1' }}
            headingText={headline}
            position="center"
          />
        </StyledHeadingWrapper>

        {subheadline && <SubtitleHeading>{subheadline}</SubtitleHeading>}
        <AddonSections
          addonSections={addonSections}
          addonSelectionState={selectedAddons}
          unavailableAddons={unavailableAddons}
          onChange={handleChange}
          onDetailsClick={onDetailsClickWithReporter}
          checkedAddons={isReactivating ? checkedAddons : []}
          notSelectableText={NOT_SELECTABLE_TEXT}
        />
        <ButtonContainer>
          <Subtext>
            {numAddonsSelected} {numAddonsSelected === 1 ? 'Extra' : 'Extras'}{' '}
            in your subscription
          </Subtext>
          {inEditFlow ? (
            <Button ref={ref} disabled={isSaveDisabled} type="submit">
              {submitButtonText}
            </Button>
          ) : (
            <Button
              ref={ref}
              disabled={isSaveDisabled}
              variant={'spinach'}
              type="submit"
            >
              {submitButtonText}
            </Button>
          )}
          {cancelAction ? (
            <Button onClick={cancelAction} variant="secondary">
              {cancelButtonText}
            </Button>
          ) : (
            <LinkButton href={cancelTarget} variant="secondary">
              {cancelButtonText}
            </LinkButton>
          )}
        </ButtonContainer>
        <AddonsBar
          shown={!refInView}
          inEditFlow={inEditFlow}
          isSaveDisabled={isSaveDisabled}
          numAddonsSelected={numAddonsSelected}
          submitButtonText={submitButtonText}
          cancelButtonText={cancelButtonText}
          cancelTarget={cancelTarget}
          cancelAction={cancelAction}
        />
      </form>
      <AddOnQuickView
        {...modal}
        onSubmitAddonAddition={handleChange}
        checkedAddons={checkedAddons}
        addonSelectionState={selectedAddons}
        defaultValues={
          selectedAddons[modal?.sku] ?? {
            quantity: 1,
            frequency: 1,
            recurrence: null,
          }
        }
        isReactivating={isReactivating}
      />

      <Footer {...FOOTER_CONTENT} />
    </>
  );
};

AddOnSelectionForm.displayName = 'AddOnSelectionForm';
AddOnSelectionForm.propTypes = {
  headline: PropTypes.string.isRequired,
  addons: PropTypes.array.isRequired,
  defaultAddons: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  addonSections: PropTypes.array.isRequired,
  subheadline: PropTypes.node.isRequired,
  unavailableAddons: PropTypes.array,
  links: PropTypes.array.isRequired,
  logoUrl: PropTypes.string.isRequired,
  logoSrText: PropTypes.string.isRequired,
  modals: PropTypes.array.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  cancelTarget: PropTypes.string.isRequired,
  cancelAction: PropTypes.func,
  inEditFlow: PropTypes.bool.isRequired,
  unavailableText: PropTypes.string.isRequired,
  isReactivating: PropTypes.bool,
};
