'use client';
import styled from 'styled-components';

export const StyledAddonsBar = styled.div`
  background-color: ${props => props.theme.colors.background.base};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.theme.spacing(3, 4)};
`;

export const ButtonContainer = styled.div`
  display: flex;
  max-width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacing(4)};
  width: 100%;
`;

export const Subtext = styled.p`
  text-align: center;
  ${props => props.theme.typography.bodyBold};
  color: ${props => props.theme.colors.text.primary};
`;
