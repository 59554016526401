import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { ResponsiveImage } from '@web/molecules';
import {
  StyledAddPupSuccess,
  Subtitle,
  InfoText,
  Title,
  InfoContainer,
} from './styles.js';

export const AddPupSuccess = ({
  image,
  title,
  subtitle,
  infoText,
  date,
  buttonLabel,
  buttonVariant,
  urlTarget,
}) => {
  return (
    <StyledAddPupSuccess>
      <ResponsiveImage {...image} height={123} width={170} />
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <InfoContainer>
        <InfoText>{infoText}</InfoText>
        <InfoText>{date}</InfoText>
      </InfoContainer>
      <LinkButton variant={buttonVariant} href={urlTarget}>
        {buttonLabel}
      </LinkButton>
    </StyledAddPupSuccess>
  );
};

AddPupSuccess.displayName = 'AddPupSuccess';

AddPupSuccess.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  infoText: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonVariant: PropTypes.string.isRequired,
  urlTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
};
