'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { interpolate } from '@format/interpolate';
import { AddPupSuccess, Footer, LoadingView } from '@web/components';
import { useSubscription } from '../../subscriptions';
import { determinePlanType, useProduct } from '../../products';
import { RedirectToLogin } from '../../router';
import { AccountHeader } from '../AccountHeader';
import { useSessionCustomer } from '../../customer';
import { useCurrentPet } from '../useCurrentPet';
import { MEAL_PREP_TEXT_REFERENCE } from './constants';
import { StyledPlanChangedSuccessContainer } from './styles.js';

export const PlanChangedSuccessContainer = ({
  header,
  footer,
  pupSuccessInfo,
}) => {
  const { pet, isLoading: petIsLoading } = useCurrentPet();
  const {
    customer,
    isActive,
    isLoading: customerIsLoading,
  } = useSessionCustomer();
  const {
    nextDeliveryDate,
    subscription,
    isLoading: subscriptionIsLoading,
  } = useSubscription(pet?.id);
  const { product, isLoading: isProductLoading } = useProduct(
    subscription?.sku,
  );

  if (!customerIsLoading && !isActive) {
    return <RedirectToLogin />;
  }

  if (
    petIsLoading ||
    subscriptionIsLoading ||
    customerIsLoading ||
    isProductLoading
  ) {
    return <LoadingView />;
  }
  const getMealPrepMessage = () => {
    const mealPrepType = determinePlanType(product.variants[0].recipes);
    return MEAL_PREP_TEXT_REFERENCE[mealPrepType] ?? '';
  };
  const interpolatedPupSuccessInfo = interpolate(pupSuccessInfo, {
    pupName: pet.name.toUpperCase(),
    mealPrepType: getMealPrepMessage(),
  });

  return (
    <>
      <AccountHeader {...header} name={customer.first_name} />
      <StyledPlanChangedSuccessContainer>
        <AddPupSuccess
          {...interpolatedPupSuccessInfo}
          date={format(nextDeliveryDate, 'PPPP')}
          urlTarget={{
            pathname: '/account',
            query: {
              pet_id: pet.id,
            },
          }}
        />
      </StyledPlanChangedSuccessContainer>
      <Footer {...footer} />
    </>
  );
};

PlanChangedSuccessContainer.displayName = 'PlanChangedSuccessContainer';

PlanChangedSuccessContainer.propTypes = {
  header: PropTypes.object.isRequired,
  footer: PropTypes.object.isRequired,
  pupSuccessInfo: PropTypes.object.isRequired,
};
