export const userUpdatesFreshRecipesEvent = (
  available_recipes,
  previous_recipes,
  new_recipes,
) => ({
  type: 'User updates fresh recipes',
  data: {
    available_recipes: available_recipes.map(({ value }) => value),
    previous_recipes,
    new_recipes,
  },
});

export const userExpandsFreshRecipeEvent = ({ value }) => ({
  type: 'User expands fresh recipe details',
  data: {
    recipe: value,
  },
});

export const userViewsFreshRecipesPage = availableNumRecipes => ({
  type: 'User views fresh recipes page',
  data: {
    available_recipes: availableNumRecipes,
  },
});
