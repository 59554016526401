export const userUpdatesBakedRecipesEvent = (
  available_recipes,
  previous_recipes,
  new_recipes,
) => {
  return {
    type: 'User updates baked recipes',
    data: {
      available_recipes,
      previous_recipes,
      new_recipes,
    },
  };
};

export const userExpandsBakedRecipeEvent = ({ value }) => ({
  type: 'User expands baked recipe details',
  data: {
    recipe: value,
  },
});

export const userViewsBakedRecipesPage = availableNumRecipes => ({
  type: 'User views baked recipes page',
  data: {
    available_recipes: availableNumRecipes,
  },
});
