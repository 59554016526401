'use client';
import styled from 'styled-components';

export const StyledEditRecipeContainer = styled.div`
  background-color: ${props => props.theme.colors.background.base};
  display: grid;
  gap: ${props => props.theme.spacing(12)};
  @media ${props => props.theme.mobileS} {
    gap: ${props => props.theme.spacing(6)};
  }
`;

export const StyledEditRecipesFormContainer = styled.div`
  display: grid;
  gap: ${props => props.theme.spacing(6)};
  background-color: ${({ theme }) => theme.colors.background.base};
  padding-top: ${({ theme }) => theme.spacing(8)};
`;
