export const userUpdatesPlanType = ({ previous_meal_prep, new_meal_prep }) => ({
  type: 'User updates meal prep type',
  data: {
    previous_meal_prep,
    new_meal_prep,
  },
});

export const userViewMealPlanTypeScreen = () => ({
  type: 'User views meal prep page',
});

export const userExpandsRecipeEvent = ({ label }) => ({
  type: 'User expands recipe details',
  data: {
    recipe: label,
  },
});
