export const pupSuccessInfoProps = {
  image: {
    src: 'https://a.storyblok.com/f/89894/x/a9d62d7d07/dog-box.svg',
    alt: 'Dog & box illustration',
  },
  title: 'Plan changed!',
  subtitle: '{{pupName}} will now receive {{mealPrepType}}',
  infoText: 'Your next box is set to arrive on:',
  buttonLabel: 'Back to Dashboard',
  buttonVariant: 'primary',
  buttonUrl: '/account',
};

export const MEAL_PREP_TEXT_REFERENCE = {
  mixed: 'a mixed bowl',
  baked: 'an all baked plan',
  fresh: 'an all fresh plan',
  half_fresh: 'a half fresh plan',
};
