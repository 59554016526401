import { addonsModalsContent } from '@web/components/AddOnQuickView/constants';
import beefImage from '../../images/our-food/rebrandBeef.jpg';
import { ADDON_SKUS } from '../constants';
import {
  supplementsDropdownsAndValues,
  treatsDropdownsAndValues,
} from '../../testing/constants';

export const addons = [
  {
    sku: ADDON_SKUS.zoomies,
    description: 'Some Description',
    title: 'Zoomies',
    productType: 'TR',
    subtitle: 'Soft Chew Treats',
    size: '1 bag (5oz.)',
    unavailableText: 'This extra is unavailable for {{pupName}}',
    pricingInfo: { price: '$37.50', discountedPrice: '$25.00' },
    image: {
      src: beefImage.src,
      alt: 'Beef Recipe Image',
      width: beefImage.width,
      height: beefImage.height,
    },
    dropdowns: treatsDropdownsAndValues.dropdowns,
  },
  {
    sku: ADDON_SKUS.chillpills,
    title: 'Chill Pills',
    productType: 'TR',
    description: 'Some Description',
    subtitle: 'Soft Chew Treats',
    size: '1 bag (5oz.)',
    unavailableText: 'This extra is unavailable for {{pupName}}',
    pricingInfo: { price: '$37.50', discountedPrice: '$25.00' },
    image: {
      src: beefImage.src,
      alt: 'Beef Recipe Image',
      width: beefImage.width,
      height: beefImage.height,
    },
    dropdowns: treatsDropdownsAndValues.dropdowns,
  },
  {
    sku: ADDON_SKUS.beefjerky,
    title: 'Beef Jerky',
    productType: 'TR',
    description: 'Some Description',
    subtitle: 'Soft Chew Treats',
    size: '1 bag (5oz.)',
    unavailableText: 'This extra is unavailable for {{pupName}}',
    pricingInfo: { price: '$37.50', discountedPrice: '$25.00' },
    image: {
      src: beefImage.src,
      alt: 'Beef Recipe Image',
      width: beefImage.width,
      height: beefImage.height,
    },
    dropdowns: treatsDropdownsAndValues.dropdowns,
  },
  {
    sku: ADDON_SKUS.bellyrubs,
    title: 'Belly Rubs',
    productType: 'SP',
    subtitle: 'Soft Chew Treats',
    description: 'Some Description',
    unavailableText: 'This extra is unavailable for {{pupName}}',
    size: '1 bag (5oz.)',
    pricingInfo: { price: '$37.50', discountedPrice: '$25.00' },
    image: {
      src: beefImage.src,
      alt: 'Beef Recipe Image',
      width: beefImage.width,
      height: beefImage.height,
    },
    dropdowns: supplementsDropdownsAndValues.dropdowns,
  },
  {
    sku: 'Supplement 2',
    title: 'Just Chicken Strips',
    productType: 'SP',
    subtitle: 'Soft Chew Treats',
    description: 'Some Description',
    unavailableText: 'This extra is unavailable for {{pupName}}',
    size: '1 bag (5oz.)',
    pricingInfo: { price: '$37.50', discountedPrice: '$25.00' },
    image: {
      src: beefImage.src,
      alt: 'Beef Recipe Image',
      width: beefImage.width,
      height: beefImage.height,
    },
    dropdowns: supplementsDropdownsAndValues.dropdowns,
  },
];

export const addonFormProps = {
  headline: 'Choose Extras for your pup',
  subheadline: '',
  links: [],
  defaultAddons: [
    {
      sku: ADDON_SKUS.zoomies,
      title: 'Just Chicken Strips',
      productType: 'TR',
      subtitle: 'Soft Chew Treats',
      size: '1 bag (5oz.)',
      pricingInfo: {
        price: '{{addonPrice}}',
        discountedPrice: '{{addonDiscount}}',
      },
      quantity: 2,
      frequency: 2,
      image: {
        src: beefImage.src,
        alt: 'Beef Recipe Image',
        width: beefImage.width,
        height: beefImage.height,
      },
      dropdowns: treatsDropdownsAndValues.dropdowns,
    },
  ],
  addonSections: [
    {
      headline: 'Treats',
      addons: addons.slice(0, 3),
    },
    {
      headline: 'Supplements',
      addons: addons.slice(3, 5),
    },
  ],
  modals: addonsModalsContent.map(content => ({
    ...content,
    ...{
      pupInfo: {
        pupName: 'Panda',
        pupPronoun: 'him',
        addonServingFrequency: '2 weeks',
      },
    },
  })),
  unavailableAddons: [],
  onChange: () => {},
  onSubmit: () => {},
  cancelAction: () => {},
  cancelButtonText: 'Cancel',
  cancelTarget: '/account',
  logoUrl: '/',
  logoSrText: 'LogoSrText',
  submitButtonText: 'Save',
  inEditFlow: true,
};

export const NOT_SELECTABLE_TEXT =
  'Only 3 Extras can be added at this time. Please remove a previous selection to add this item.';
