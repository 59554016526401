'use client';
import styled from 'styled-components';

export const SubtitlePrice = styled.p`
  text-align: center;
  ${props => props.theme.typography.subheading1};
  color: ${props => props.theme.colors.secondary.kale};
  padding: ${props => props.theme.spacing(4, 0, 2, 0)};
`;

export const SubtitleHeading = styled.p`
  text-align: center;
  max-width: 688px;
  margin: ${props => props.theme.spacing(2)} auto 0;
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.text.primary};
  padding-top: ${props => props.theme.spacing(6)};
  padding-bottom: ${props => props.theme.spacing(10)};
  @media ${props => props.theme.tablet} {
    padding-top: ${props => props.theme.spacing(2)};
    padding-bottom: ${props => props.theme.spacing(10)};
    ${props => props.theme.typography.body};
  }
`;

export const GFWarningText = styled.p`
  color: ${({ theme }) => theme.colors.status.errorPrimary};
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.14px;
  margin: ${props => props.theme.spacing(0, 8, 10, 8)};
`;
export const Subtext = styled.p`
  text-align: center;
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.text.primary};
`;

export const ButtonContainer = styled.div`
  margin: auto;
  max-width: 512px;
  display: grid;
  text-align: center;
  width: 100%;
  gap: ${props => props.theme.spacing(4)};
  padding: ${props => props.theme.spacing(16, 4, 40)};
  @media ${props => props.theme.mobileS} {
    gap: ${props => props.theme.spacing(6)};
    padding: ${props => props.theme.spacing(2, 4, 24)};
  }
`;
