'use client';
import styled from 'styled-components';

export const StyledPlanChangedSuccessContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.base};
  padding: ${({ theme }) => theme.spacing(25, 0, 12)};
  display: flex;
  justify-content: center;
  align-items: center;
`;
